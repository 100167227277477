@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.downloadButtons{*/
/*  @apply flex-col flex p-4 pl-6 mx-auto;*/
/*}*/

/*@media only screen and (min-width: 640px) {*/
/*  !* For desktop: *!*/
/*  .downloadButtons {@apply flex-row}*/

/*}*/

.downloadButtons {
  @apply flex-row flex p-4 mx-auto;
}

.downloadButton{
  @apply ml-3 bg-logo text-white rounded-full  disabled:pointer-events-none disabled:bg-button-disabled;
  padding: 0.5rem 4rem;
  font-size: 1rem;
}

.downloadButton2{
  @apply text-white bg-light-green rounded-full px-20 py-2 mr-3 disabled:pointer-events-none disabled:bg-button-disabled;
  font-size: 1rem;
}

@media only screen and (max-width: 1220px) {
  .downloadButtons {
    @apply flex-col flex justify-evenly;
  }

  .downloadButton{
    @apply mt-3 ml-0;
  }

  .downloadButton2{
    @apply m-0;
  }
}

